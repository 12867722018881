
  h1 {
    text-align: center;
  }
  
  h2 {
    padding: 2rem;
  }

  h3 {
    padding: 3rem;
  }

  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
   
.donate-link {
    padding: 3rem;
}