  
  h1 {
    text-align: center;
  }

  .container {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 0 auto;
  }
  
  .wrapper {
    position: relative;
    margin: 50px 0 45px;
  }
  
  .items {
    margin-bottom: 24px;
  }
  
  .item {
    display: flex;
    flex: 1;
    margin: 0 1rem;
    width: 240px;
    border-radius: 10px;
  }
  
  .item_text {
    padding: 4rem;
    background: #fff;
    text-decoration: none;
}


  .item_link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    color: #000;
    align-items: center;

  }
  
  
  
  @media only screen and (min-width: 1024px) {
    .items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .item {
      margin-bottom: 2rem;
    }
  }
  