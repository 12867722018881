body {
	justify-content: center;
	align-items: center;
	margin: 0;
	color:#000;
	background-color: #FFF;
}

.console {
    height: 36vh;
}

.result-block {
	margin:0;
}

.chosen-block {
    margin:0;
}

.img-display {
	height: 100vh;
	margin:0;
    padding:20px;
}


.loader-block {
	text-align:center; 
	margin: 18vh 47%;
}
.loader {
	border: 66px solid #f3f3f3;
	border-top: 66px solid red;
	border-radius: 100%;
	width: 1px;
	height: 1px;
	animation: spin 4.5s linear infinite;
	text-align: center;
}
  

.container {
	text-align: center;
}

a {
	color: red;

}

h1 {
	font-size: 24px;
}


.buttons {
	padding:20px;
	margin:0;
}
.button {
    display: inline-block;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
}

.primary {
    background-color: #000;
}

.secondary {
    background-color: #e74c3c;
}


@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
  