* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', 'PT Sans', sans-serif;
}


.container {
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 { font-size: 48px; }
h2 { font-size: 44px; }
h3 { font-size: 40px; }
h4 { font-size: 36px; }
h5 { font-size: 32px; }

p {
  font-size: 18px;  
  font-weight: normal;
}


.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('./assets/images/analytics.jpeg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.purchase {
  display: flex;
  align-items: flex-end;
  justify-content: right;
  padding: 45px;
}

.products {
  background-image: url('./assets/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  background-image: url('./assets/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
