.post-container {
    display: flex;
    flex-direction: column;
    padding: 3%;
    margin: 0 2%;
}
.heading {
    height: 126px;
    text-align: center;
    display: flex;
    align-items: center;
}
.image {
    width: 100%;
    height: 210px;
}